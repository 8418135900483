import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  { path: "/", redirect: { name: "companies" } },

  {
    path: "/companies",
    name: "companies",
    component: () => import("@/views/companies/companies.vue"),
    meta: {
      title: "الشركات",
    },
  },
  {
    path: "/companies/:id",
    name: "edit",
    component: () => import("@/views/companies/Edit.vue"),
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      title: "تسجيل الدخول",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  let isLogged = localStorage.getItem("accessToken") !== null;
  if (to.name !== "login" && !isLogged) {
    return next({ name: "login" });
  } else if (isLogged && to.name !== 'edit') {
    let userData = JSON.parse(localStorage.getItem('userData'));
    if(userData.role.name !== 'admin'){
      return next({ name: "edit", params: { id: userData.company.id } });
    }
  }
  return next();
});
export default router;
